import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, doc, updateDoc, query, where, setDoc, getDocs } from 'firebase/firestore'

export const saveSource = createAsyncThunk('sources/saveSource', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'sources', id), data)
	} else {
		const sourceId = doc(collection(firestore, 'sources')).id
		return setDoc(doc(firestore, 'sources', sourceId), data, { merge: true })
	}
})

export const fetchSources = createAsyncThunk('sources/fetchSources', async ({ venueIds = [], venueId }, { dispatch }) => {
	try {
		if (venueIds.length > 1) {
			const req = await Promise.all(
				Array.from({ length: Math.ceil(venueIds.length / 10) }).map((_, index) =>
					getDocs(query(collection(firestore, 'sources'), where('etablissement', 'in', venueIds.slice(index * 10, (index + 1) * 10))))
				)
			)
			const sources = req
				.reduce((res, querySnapshot) => [...res, ...querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))], [])
				.sort((a, b) => {
					if (a.nom > b.nom) return 1
					if (a.nom < b.nom) return -1
					return 0
				})

			dispatch(setSources(sources.filter(({ etablissement }) => etablissement === venueId)))
			dispatch(setAllSources(sources))
		} else {
			const querySnapshot = await getDocs(query(collection(firestore, 'sources'), where('etablissement', '==', venueId)))
			const sources = querySnapshot.docs
				.map(doc => ({ ...doc.data(), id: doc.id }))
				.sort((a, b) => {
					if (a.nom > b.nom) return 1
					if (a.nom < b.nom) return -1
					return 0
				})
			dispatch(setSources(sources))
			dispatch(setAllSources(sources))
		}
	} catch (error) {
		console.log(error)
	}
})

// export const fetchSources = createAsyncThunk('sources/fetchSources', async (venueId, { dispatch }) => {
// 	try {
// 		const querySnapshot = await getDocs(query(collection(firestore, 'sources'), where('etablissement', '==', venueId)))
// 		const sources = querySnapshot.docs
// 			.filter(doc => !doc.data().isDeleted)
// 			.map(doc => ({ ...doc.data(), id: doc.id }))
// 			.sort((a, b) => {
// 				if (a.nom > b.nom) return 1
// 				if (a.nom < b.nom) return -1
// 				return 0
// 			})

// 		dispatch(setSources(sources))
// 	} catch (error) {
// 		console.log(error)
// 	}
// })

export const sourcesSlice = createSlice({
	name: 'sources',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
		all_sources: [],
	},
	reducers: {
		setSources: (state, action) => {
			state.value = action.payload
		},
		setSourceStatus: (state, action) => {
			state.status = action.payload
		},
		setAllSources: (state, action) => {
			state.all_sources = action.payload
		},
	},
	extraReducers: {
		[saveSource.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveSource.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveSource.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

// export const fetchSources = venueId => dispatch => {
// 	return onSnapshot(
// 		query(collection(firestore, 'sources'), where('etablissement', '==', venueId)),
// 		querySnapshot => {
// 			const sources = querySnapshot.docs
// 				.filter(doc => !doc.data().isDeleted)
// 				.map(doc => ({ ...doc.data(), id: doc.id }))
// 				.sort((a, b) => {
// 					if (a.nom > b.nom) return 1
// 					if (a.nom < b.nom) return -1
// 					return 0
// 				})
// 			dispatch(setIsSourcesLoaded(true))
// 			dispatch(setSources(sources))
// 		},
// 		error => {
// 			console.log(error)
// 		}
// 	)
// }

export const getSources = state => state.sources.value
export const getAllSources = state => state.sources.all_sources
export const getSourceStatus = state => state.sources.status

export const { setSources, setSourceStatus, setAllSources } = sourcesSlice.actions

export default sourcesSlice.reducer
