import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, doc, updateDoc, query, where, getDoc, documentId, getDocs, setDoc } from 'firebase/firestore'

export const saveAccount = createAsyncThunk('accounts/saveAccount', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'accounts', id), data)
	} else {
		const accountId = doc(collection(firestore, 'accounts')).id
		return setDoc(doc(firestore, 'accounts', accountId), data, { merge: true })
	}
})

export const accountsSlice = createSlice({
	name: 'accounts',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
		selectedAccount: {},
		isAccountDetailModalOpen: false,
	},
	reducers: {
		setAccountStatus: (state, action) => {
			state.status = action.payload
		},
		setSelectedAccount: (state, action) => {
			state.selectedAccount = action.payload
		},
		setIsAccountDetailModalOpen: (state, action) => {
			state.isAccountDetailModalOpen = action.payload
		},
	},
	extraReducers: {
		[saveAccount.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveAccount.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveAccount.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const loadAccountFromId = async accountId => {
	const docSnap = await getDoc(doc(firestore, 'accounts', accountId))
	const { lastReservationDate, ...data } = docSnap.data()
	return { ...data, id: docSnap.id }
}

export const fetchAccountsByBatches = accountsIds => {
	const batchSize = 10
	const arrAccountsIds = []

	for (let i = 0; i < accountsIds.length; i += batchSize) {
		const batch = accountsIds.slice(i, i + batchSize)
		const promise = getDocs(query(collection(firestore, 'accounts'), where(documentId(), 'in', batch)))
		arrAccountsIds.push(promise)
	}

	return Promise.all(arrAccountsIds)
}

export const getNewAccountId = () => {
	return doc(collection(firestore, 'accounts')).id
}

export const updateAccount = (id, data) => {
	return updateDoc(doc(firestore, 'accounts', id), data)
}

export const getAccountStatus = state => state.accounts.status
export const getSelectedAccount = state => state.accounts.selectedAccount
export const getIsAccountDetailModalOpen = state => state.accounts.isAccountDetailModalOpen

export const { setAccountStatus, setSelectedAccount, setIsAccountDetailModalOpen } = accountsSlice.actions

export default accountsSlice.reducer
