import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore, storage } from '../../backend'
import dayjs from 'dayjs'
import { collection, doc, updateDoc, query, where, onSnapshot, writeBatch, getDocs, getDoc } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage'

export const saveFile = createAsyncThunk('files/saveFile', async ({ id, data, payment }) => {
	if (id) {
		return updateDoc(doc(firestore, 'files', id), data)
	} else {
		const fileId = doc(collection(firestore, 'files')).id
		const batch = writeBatch(firestore)

		batch.set(doc(firestore, 'files', fileId), { ...data, creationDate: dayjs().unix() }, { merge: true })

		if (payment) {
			const paymentId = doc(collection(firestore, 'payments')).id
			batch.set(doc(firestore, 'payments', paymentId), { ...payment, creationDate: dayjs().unix(), file: fileId }, { merge: true })
		}

		return batch.commit()
	}
})

export const fetchFiles = createAsyncThunk('files/fetchFiles', async (venueId, { dispatch }) => {
	try {
		const querySnapshot = await getDocs(query(collection(firestore, 'files'), where('etablissement', '==', venueId), where('isGlobal', '==', true)))
		const files = querySnapshot.docs
			.map(doc => ({ ...doc.data(), id: doc.id }))
			.sort((a, b) => {
				if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
				if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
				return 0
			})
		dispatch(setFiles(files))
	} catch (error) {
		console.log(error)
	}
})

export const filesSlice = createSlice({
	name: 'files',
	initialState: {
		value: [],
		status: 'idle',
		filesOfBooking: [],
		error: null,
	},
	reducers: {
		setFiles: (state, action) => {
			state.value = action.payload
		},
		setFileStatus: (state, action) => {
			state.status = action.payload
		},
		setFilesOfBooking: (state, action) => {
			state.filesOfBooking = action.payload
		},
	},
	extraReducers: {
		[saveFile.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveFile.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveFile.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchFilesOfBooking = (bookingId, venueId) => dispatch => {
	return onSnapshot(
		query(collection(firestore, 'files'), where('reservation', '==', bookingId), where('etablissement', '==', venueId)),
		querySnapshot => {
			const files = querySnapshot.docs
				.map(doc => {
					const { dateDB, ...data } = doc.data()
					return { ...data, id: doc.id }
				})
				.filter(({ isDeleted }) => !isDeleted)
			dispatch(setFilesOfBooking(files))
		},
		error => {
			console.log(error)
		}
	)
}

export const fetchFilesOfGroup = (groupId, venueId) => dispatch => {
	return onSnapshot(
		query(collection(firestore, 'files'), where('groupId', '==', groupId), where('etablissement', '==', venueId)),
		querySnapshot => {
			const files = querySnapshot.docs
				.map(doc => {
					const { dateDB, ...data } = doc.data()
					return { ...data, id: doc.id }
				})
				.filter(({ isDeleted }) => !isDeleted)
			dispatch(setFilesOfBooking(files))
		},
		error => {
			console.log(error)
		}
	)
}

export const loadFileFromId = async fileId => {
	const docSnap = await getDoc(doc(firestore, 'files', fileId))
	return { ...docSnap.data(), id: docSnap.id }
}

export const updateFiles = data => async (_, getState) => {
	const files = getState().files.filesOfBooking
	const batch = writeBatch(firestore)

	files.forEach(file => {
		batch.update(doc(firestore, 'files', file.id), data)
	})

	return batch.commit()
}

export const uploadFile = async (path, file) => {
	const storageRef = ref(storage, path)
	const snapshot = await uploadBytes(storageRef, file)
	const url = await getDownloadURL(snapshot.ref)
	return { url, name: snapshot.metadata.name }
}

export const getDownloadURLFromPath = async path => {
	const storageRef = ref(storage, path)
	const url = await getDownloadURL(storageRef)
	return url
}

export const uploadFileResumable = (path, file) => {
	const storageRef = ref(storage, path)
	const uploadTask = uploadBytesResumable(storageRef, file)
	return { uploadTask, getDownloadURL }
}

export const cancelFile = (fileId, payments = []) => {
	const batch = writeBatch(firestore)
	batch.update(doc(firestore, 'files', fileId), { status: 'canceled' })

	payments.forEach(payment => {
		batch.update(doc(firestore, 'payments', payment.id), { status: 'canceled' })
	})

	return batch.commit()
}

export const updateFile = (id, data) => {
	return updateDoc(doc(firestore, 'files', id), data)
}

export const getFiles = state => state.files.value
export const getFilesStatus = state => state.files.status
export const getFilesOfBooking = state => state.files.filesOfBooking

export const { setFiles, setFileStatus, setFilesOfBooking } = filesSlice.actions

export default filesSlice.reducer
