import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import dayjs from 'dayjs'
import { collection, doc, updateDoc, setDoc, query, where, onSnapshot, getDocs, writeBatch, getDoc } from 'firebase/firestore'

export const saveBooking = createAsyncThunk('bookings/saveBooking', async ({ id, data }) => {
	return updateDoc(doc(firestore, 'reservations', id), data)
})

export const bookingsSlice = createSlice({
	name: 'bookings',
	initialState: {
		value: [],
		specialValue: [],
		status: 'idle',
		isBookingsLoaded: false,
		isBookingsSpecialLoaded: false,
		error: null,
		selectedBooking: {},
		isBookingDrawerOpen: false,
		isBookingModalOpen: false,
		selectedDate: dayjs().format('DD-MM-YYYY'),
		bookingDataToImport: null,
	},
	reducers: {
		setBookings: (state, action) => {
			state.value = action.payload.data
		},
		setSpecialBookings: (state, action) => {
			state.specialValue = action.payload.data
		},
		resetBookings: state => {
			state.value = []
			state.specialValue = []
		},
		setBookingStatus: (state, action) => {
			state.status = action.payload
		},
		setSelectedBooking: (state, action) => {
			state.selectedBooking = action.payload
		},
		setIsBookingDrawerOpen: (state, action) => {
			state.isBookingDrawerOpen = action.payload
		},
		setIsBookingModalOpen: (state, action) => {
			state.isBookingModalOpen = action.payload
		},
		setSelectedDate: (state, action) => {
			state.selectedDate = action.payload
		},
		setIsBookingsLoaded: (state, action) => {
			state.isBookingsLoaded = action.payload
		},
		setIsBookingsSpecialLoaded: (state, action) => {
			state.isBookingsSpecialLoaded = action.payload
		},
		setBookingDataToImport: (state, action) => {
			state.bookingDataToImport = action.payload
		},
	},
	extraReducers: {
		[saveBooking.pending]: state => {
			state.status = 'loading'
		},
		[saveBooking.fulfilled]: state => {
			state.status = 'succeeded'
		},
		[saveBooking.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchBookings = venueId => (dispatch, getState) => {
	// const threshold = dayjs().startOf('month').startOf('week').toDate()
	// const status = getState().status.value

	// const pseudoDoneIds = status.filter(({ type }) => type === 'pseudo_done').map(({ id }) => id)
	// const bookingsQuery =
	// 	pseudoDoneIds.length > 0
	// 		? query(
	// 				collection(firestore, 'reservations'),
	// 				and(where('etablissement', '==', venueId), or(where('dateDB', '>=', threshold), where('statusId', 'in', pseudoDoneIds)))
	// 		  )
	// 		: query(collection(firestore, 'reservations'), where('etablissement', '==', venueId), where('dateDB', '>=', threshold))

	const statusIds = getState()
		.status.value.filter(({ type }) => ['canceled', 'archived'].indexOf(type) === -1)
		.map(({ id }) => id)

	const bookingsQuery = query(collection(firestore, 'reservations'), where('etablissement', '==', venueId), where('statusId', 'in', statusIds))

	return onSnapshot(
		bookingsQuery,
		querySnapshot => {
			const bookings = querySnapshot.docs.reduce((res, doc) => {
				const { dateDB, ...data } = doc.data()
				return [...res, { ...data, id: doc.id }]
			}, [])

			// const statusIds = getState()
			// 	.status.value.filter(({ type }) => ['canceled', 'archived'].indexOf(type) === -1)
			// 	.map(({ id }) => id)

			// dispatch(setBookings({ data: bookings.filter(({ statusId }) => statusIds.indexOf(statusId) > -1) }))
			dispatch(setIsBookingsLoaded(true))
			dispatch(setBookings({ data: bookings }))
		},
		error => {
			console.log(error)
			dispatch(setIsBookingsLoaded(true))
		}
	)
}

export const fetchBookingsOfClients = async (clients, venuesGroup) => {
	const bookingsResults = await clients.reduce(async (accPromise, client) => {
		const acc = await accPromise
		try {
			const clientBookings = await fetchBookingsOfClientId(client, venuesGroup)
			return [...acc, ...clientBookings]
		} catch (error) {
			console.error(`Erreur lors de la récupération des réservations pour le client ${client.id}:`, error)
			return acc
		}
	}, Promise.resolve([]))

	return bookingsResults.sort((a, b) => dayjs(b.dateDB).diff(dayjs(a.dateDB)))
}

export const fetchBookingsOfClientId = async (client, venuesGroup) => {
	let bookings = []

	if (client.venuesGroupId) {
		const venuesChunks = []
		for (let i = 0; i < venuesGroup.venues.length; i += 10) {
			venuesChunks.push(venuesGroup.venues.slice(i, i + 10))
		}

		const bookingsPromises = venuesChunks.map(venuesChunk =>
			getDocs(query(collection(firestore, 'reservations'), where('etablissement', 'in', venuesChunk), where('client', '==', client.id)))
		)
		const bookingsResults = await Promise.all(bookingsPromises)

		bookings = bookingsResults.flatMap(req => req.docs.map(doc => ({ ...doc.data(), id: doc.id })))
	} else {
		const req = await getDocs(
			query(collection(firestore, 'reservations'), where('etablissement', '==', client.etablissement), where('client', '==', client.id))
		)
		bookings = req.docs.map(doc => ({ ...doc.data(), id: doc.id }))
	}

	return bookings.sort((a, b) => dayjs(b.dateDB).diff(dayjs(a.dateDB)))
}

export const updateBookingsByBatch = (bookings, data) => {
	const batch = writeBatch(firestore)
	bookings.forEach(booking => {
		batch.update(doc(firestore, 'reservations', booking.id), data)
	})
	return batch.commit()
}

export const updateBooking = (id, data) => {
	return updateDoc(doc(firestore, 'reservations', id), data)
}

export const getBookingFromId = async id => {
	const snapDoc = await getDoc(doc(firestore, 'reservations', id))
	const { dateDB, ...rest } = snapDoc.data()
	return { ...rest, id: snapDoc.id }
}

export const getBookingsFromGroupId = async (groupId, status, venueId) => {
	const cancelIds = status.filter(({ type }) => type === 'canceled' || type === 'archived').map(({ id }) => id)
	const querySnapshot = await getDocs(
		query(collection(firestore, 'reservations'), where('etablissement', '==', venueId), where('groupId', '==', groupId))
	)
	if (querySnapshot.docs.filter(doc => cancelIds.indexOf(doc.data().statusId) === -1).length === 0) {
		return querySnapshot.docs.map(doc => {
			const { dateDB, ...rest } = doc.data()
			return { ...rest, id: doc.id }
		})
	}
	return querySnapshot.docs
		.filter(doc => cancelIds.indexOf(doc.data().statusId) === -1)
		.map(doc => {
			const { dateDB, ...rest } = doc.data()
			return { ...rest, id: doc.id }
		})
}

export const getNewBookingId = () => {
	return doc(collection(firestore, 'bookings')).id
}

export const addNewBooking = (bId, bData) => {
	return setDoc(doc(firestore, 'reservations', bId), bData, { merge: true })
}

export const getBookings = state => state.bookings.value
export const getBookingStatus = state => state.bookings.status
export const getSelectedBooking = state => state.bookings.selectedBooking
export const getIsBookingDrawerOpen = state => state.bookings.isBookingDrawerOpen
export const getIsBookingModalOpen = state => state.bookings.isBookingModalOpen
export const getSelectedDate = state => state.bookings.selectedDate
export const getIsBookingsLoaded = state => state.bookings.isBookingsLoaded
export const getIsBookingsSpecialLoaded = state => state.bookings.isBookingsSpecialLoaded
export const getBookingDataToImport = state => state.bookings.bookingDataToImport

export const {
	setBookingStatus,
	setSelectedBooking,
	setIsBookingDrawerOpen,
	setIsBookingModalOpen,
	setSelectedDate,
	setIsBookingsLoaded,
	setIsBookingsSpecialLoaded,
	resetBookings,
	setBookings,
	setSpecialBookings,
	setBookingDataToImport,
} = bookingsSlice.actions

export default bookingsSlice.reducer
