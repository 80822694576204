import { configureStore } from '@reduxjs/toolkit'
import usersReducer from './reducers/usersReducer'
import venuesReducer from './reducers/venuesReducer'
import bookingsReducer from './reducers/bookingsReducer'
import clientsReducer from './reducers/clientsReducer'
import accountsReducer from './reducers/accountsReducer'
import spacesReducer from './reducers/spacesReducer'
import sourcesReducer from './reducers/sourcesReducer'
import productsReducer from './reducers/productsReducer'
import customDatesReducer from './reducers/customDatesReducer'
import slotsReducer from './reducers/slotsReducer'
import templatesReducer from './reducers/templatesReducer'
import utilsReducer from './reducers/utilsReducer'
import filesReducer from './reducers/filesReducer'
import variablesReducer from './reducers/variablesReducer'
import linksReducer from './reducers/linksReducer'
import basesReducer from './reducers/basesReducer'
import paymentsReducer from './reducers/paymentsReducer'
import stepsReducer from './reducers/stepsReducer'
import customFieldsReducer from './reducers/customFieldsReducer'
import statusReducer from './reducers/statusReducer'
import roomsReducer from './reducers/roomsReducer'

export default configureStore({
	reducer: {
		users: usersReducer,
		venues: venuesReducer,
		bookings: bookingsReducer,
		clients: clientsReducer,
		accounts: accountsReducer,
		spaces: spacesReducer,
		sources: sourcesReducer,
		products: productsReducer,
		customDates: customDatesReducer,
		slots: slotsReducer,
		templates: templatesReducer,
		utils: utilsReducer,
		files: filesReducer,
		variables: variablesReducer,
		links: linksReducer,
		bases: basesReducer,
		payments: paymentsReducer,
		steps: stepsReducer,
		customFields: customFieldsReducer,
		status: statusReducer,
		rooms: roomsReducer,
	},
})
