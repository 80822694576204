import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'
import { collection, doc, updateDoc, query, where, setDoc, getDocs } from 'firebase/firestore'

export const saveSpace = createAsyncThunk('spaces/saveSpace', async ({ id, data }) => {
	if (id) {
		return updateDoc(doc(firestore, 'espaces', id), data)
	} else {
		const spaceId = doc(collection(firestore, 'espaces')).id
		return setDoc(doc(firestore, 'espaces', spaceId), data, { merge: true })
	}
})

export const fetchSpaces = createAsyncThunk('spaces/fetchSpaces', async ({ venueIds = [], venueId }, { dispatch }) => {
	try {
		if (venueIds.length > 1) {
			const req = await Promise.all(
				Array.from({ length: Math.ceil(venueIds.length / 10) }).map((_, index) =>
					getDocs(query(collection(firestore, 'espaces'), where('etablissement', 'in', venueIds.slice(index * 10, (index + 1) * 10))))
				)
			)
			const noSortSpaces = req.reduce((res, querySnapshot) => [...res, ...querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))], [])

			const spaces = [
				...noSortSpaces
					.filter(({ order }) => order)
					.sort((a, b) => {
						if (parseFloat(a['order']) < parseFloat(b['order'])) return -1
						if (parseFloat(a['order']) > parseFloat(b['order'])) return 1
						return 0
					}),
				...noSortSpaces
					.filter(({ order }) => !order)
					.sort((a, b) => {
						return a.nom.localeCompare(b.nom)
					}),
			]

			dispatch(setSpaces(spaces.filter(({ etablissement }) => etablissement === venueId)))
			dispatch(setAllSpaces(spaces))
		} else {
			const querySnapshot = await getDocs(query(collection(firestore, 'espaces'), where('etablissement', '==', venueId)))
			const noSortSpaces = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))

			const spaces = [
				...noSortSpaces
					.filter(({ order }) => order)
					.sort((a, b) => {
						if (parseFloat(a['order']) < parseFloat(b['order'])) return -1
						if (parseFloat(a['order']) > parseFloat(b['order'])) return 1
						return 0
					}),
				...noSortSpaces
					.filter(({ order }) => !order)
					.sort((a, b) => {
						return a.nom.localeCompare(b.nom)
					}),
			]

			dispatch(setSpaces(spaces))
			dispatch(setAllSpaces(spaces))
		}
	} catch (error) {
		console.log(error)
	}
})

export const spacesSlice = createSlice({
	name: 'spaces',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
		all_spaces: [],
	},
	reducers: {
		setSpaces: (state, action) => {
			state.value = action.payload
		},
		setSpaceStatus: (state, action) => {
			state.status = action.payload
		},
		setAllSpaces: (state, action) => {
			state.all_spaces = action.payload
		},
	},
	extraReducers: {
		[saveSpace.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveSpace.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveSpace.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const loadSpacesFromVenue = async venueId => {
	const q = query(collection(firestore, 'espaces'), where('etablissement', '==', venueId))
	const querySnapshot = await getDocs(q)
	return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
}

export const getSpaces = state => state.spaces.value
export const getSpaceStatus = state => state.spaces.status
export const getAllSpaces = state => state.spaces.all_spaces

export const { setSpaces, setSpaceStatus, setAllSpaces } = spacesSlice.actions

export default spacesSlice.reducer
