import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore, getCallableFunctionV2 } from '../../backend'
import { fetchVenues, fetchVenuesGroup } from './venuesReducer'
import * as Sentry from '@sentry/react'
import { updateDoc, doc, onSnapshot } from 'firebase/firestore'

export const saveUser = createAsyncThunk('users/saveUser', async ({ id, data }) => {
	return updateDoc(doc(firestore, 'users', id), data)
})

export const fetchUsers = createAsyncThunk('users/fetchUsers', async ({ venueIds = [], venueId }, { dispatch }) => {
	try {
		const fetchUsersFunction = getCallableFunctionV2('fetchUsers')
		const result = await fetchUsersFunction({ venueIds, venueId })

		const users = result.data.users

		if (venueId) {
			dispatch(setUsers(users.filter(({ venues }) => venues.includes(venueId))))
		}

		dispatch(setAllUsers(users))

		return users
	} catch (error) {
		console.error('Error fetching users:', error)

		if (error.code === 'invalid-argument') {
			throw new Error('Les paramètres fournis sont invalides.')
		} else if (error.code === 'internal') {
			throw new Error('Une erreur interne est survenue sur le serveur.')
		} else {
			throw new Error('Une erreur inattendue est survenue.')
		}
	}
})

export const usersSlice = createSlice({
	name: 'users',
	initialState: {
		activeUser: {},
		value: [],
		all_users: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setActiveUser: (state, action) => {
			state.activeUser = action.payload
		},
		setUsers: (state, action) => {
			state.value = action.payload
		},
		setUserStatus: (state, action) => {
			state.status = action.payload
		},
		setAllUsers: (state, action) => {
			state.all_users = action.payload
		},
	},
	extraReducers: {
		[saveUser.pending]: state => {
			state.status = 'loading'
		},
		[saveUser.fulfilled]: state => {
			state.status = 'succeeded'
		},
		[saveUser.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchActiveUser = (userId, handleSetListeners, handleSetSelectedVenueRelatedListeners, isEmailVerified) => dispatch => {
	return onSnapshot(
		doc(firestore, 'users', userId),
		doc => {
			if (doc.exists) {
				const user = doc.data()
				dispatch(setActiveUser({ ...user, isEmailVerified, id: userId }))
				const arr = [dispatch(fetchVenues(userId, handleSetSelectedVenueRelatedListeners, !!user.hqTypesenseApiKey))]
				if (user.venuesGroupId) {
					arr.push(dispatch(fetchVenuesGroup(user.venuesGroupId)))
				}
				handleSetListeners(arr)
				user?.email && Sentry.setUser({ email: user.email, id: userId })
			}
		},
		error => {
			console.log(error)
		}
	)
}

export const getActiveUser = state => state.users.activeUser
export const getUsers = state => state.users.value
export const getActiveUserStatus = state => state.users.status
export const getAllUsers = state => state.users.all_users

export const { setActiveUser, setUsers, setUserStatus, setAllUsers } = usersSlice.actions

export default usersSlice.reducer
